import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import { RegularPageFluidBox, RegularPageTitleBox, RegularPageContentR5Box } from '../../themes/boxLayout'
import { EdugainDocsItemBox } from '../../themes/boxEdugain'
import { AboutDescriptionBox } from '../../themes/boxAbout'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiCompany } from '../../helpers/urls'
import useWindowDimensions from '../../helpers/dimensions'
import BackdropElement from '../layout/BackdropElement'
import ErrorMessage from '../layout/ErrorMessage'
import HelmetElement from '../layout/HelmetElement'

const Complaints = () => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const [page, setPage] = useState([])
  const [pageParams, setPageParams] = useState(null)
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)
  const backdropOpenRef = useRef(backdropOpen)
  backdropOpenRef.current = backdropOpen

  const sxContainer = { paddingTop: width > settings.desktopBreakpoint ? '104px' : '0px' }

  const handleComplaintsFileOpen = () => {
    if (pageParams && pageParams.complaints_file) {
      const url = settings.s3URL + settings.s3Bucket + pageParams.complaints_file
      window.open(url, '_blank')
    }    
  }

  const handleComComUrlOpen = () => {
    if (pageParams && pageParams.complaints_8 && pageParams.complaints_8[lang]) {
      const url = pageParams.complaints_8[lang]
      window.open(url, '_blank')
    }    
  }

  useEffect(() => {
    const fetchPage = async () => {
      const response = await request(settings.domain + settings.api + apiCompany.getInfo, 'GET')

      if (response && response.length > 0) {
        setPage(response)
        setBackdropOpen(false)
        setErrorMessageOpen(false)
      } else {
        setPage([])
      }
    }

    if (settings.mode === 'live') {
      fetchPage()
    } else {
      setPage([])
    }

    setTimeout(() => {
      if (backdropOpenRef.current) {
        setBackdropOpen(false)
        setErrorMessageOpen(true)
      }
    }, settings.errorMessageTimeout)
  }, [])

  useEffect(() => {
    setPageParams(page && page.length > 0 && page[0].params ? page[0].params : null)
  }, [page])
  
  return (
    <>
      <HelmetElement
        metaDescription='Providing Information Technology services to users and implementation of new, innovative services, Providing software products and cyber security solutions to customers'
      />

      <RegularPageFluidBox>
        <BackdropElement open={backdropOpen} />
        <ErrorMessage open={errorMessageOpen} />
        <Container maxWidth='xl' sx={sxContainer}> 
          <Grid container>
            <Grid xs={12} lg={5}>
              <RegularPageTitleBox>{pageParams && pageParams.complaints_1 && pageParams.complaints_1[lang] ? pageParams.complaints_1[lang] : ''}</RegularPageTitleBox>
            </Grid>
          </Grid>
          <RegularPageContentR5Box>
            <AboutDescriptionBox>
              <br />
              <Box>{pageParams && pageParams.complaints_2 && pageParams.complaints_2[lang] ? pageParams.complaints_2[lang] : ''}</Box>
              <br />

              <Box>{pageParams && pageParams.complaints_3 && pageParams.complaints_3[lang] ? pageParams.complaints_3[lang] : ''}</Box>
              <br />

              <Box>
                <Box component='span'>{pageParams && pageParams.complaints_4 && pageParams.complaints_4[lang] ? pageParams.complaints_4[lang] : ''} </Box>
                <EdugainDocsItemBox component='span' onClick={handleComplaintsFileOpen} onMouseDown={handleComplaintsFileOpen}> {pageParams && pageParams.complaints_5 && pageParams.complaints_5[lang] ? pageParams.complaints_5[lang] : ''}</EdugainDocsItemBox>
                <Box component='span'> {pageParams && pageParams.complaints_6 && pageParams.complaints_6[lang] ? pageParams.complaints_6[lang] : ''}</Box>
              </Box>
              <br />

              <Box>
                <Box component='span'>{pageParams && pageParams.complaints_4 && pageParams.complaints_7[lang] ? pageParams.complaints_7[lang] : ''} </Box>
                <EdugainDocsItemBox component='span' onClick={handleComComUrlOpen} onMouseDown={handleComComUrlOpen}>{pageParams && pageParams.complaints_8 && pageParams.complaints_8[lang] ? pageParams.complaints_8[lang] : ''} </EdugainDocsItemBox>
              </Box>

            </AboutDescriptionBox>
          </RegularPageContentR5Box>
        </Container>
      </RegularPageFluidBox>
    </>
  )
}

export default Complaints