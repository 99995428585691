import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import headerBackground from './images/header_background.png'
import footerBackground from './images/footer_background.png'
import regularBackgroundLeft from './images/regular_page_background_left.png'
import regularBackground from './images/regular_page_background.png'
import burgerMenuIcon from './images/burger_menu_icon.png'
import closeIcon from './images/close_icon.png'

export const WrapperBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 'calc(100vh - 224px)',
})

export const HeaderBox = styled(Box)({
  height: '224px',
  background: `#001834 url(${headerBackground}) no-repeat right center`,
  transition: 'all 0.3s',
})

export const HeaderMobileBox = styled(Box)({
  minHeight: '100px',
  background: `#001834 url(${headerBackground}) no-repeat right center / auto 100%`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0px 20px',
})

export const HeaderFixedBox = styled(Box)({
  width: '100%',
  height: '120px',
  background: `#001834 url(${headerBackground}) no-repeat right center`,
  position: 'fixed',
  top: '0px',
  left: '0px',
  zIndex: '101',
  transition: 'all 0.3s',
  boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.35)',
})

export const FooterBox = styled(Box)({
  // height: '274px',
  height: '305px',
  background: `#001834 url(${footerBackground}) no-repeat right center`,

  '@media (max-width: 1199px)': {
    height: 'auto',
    background: `#001834 url(${footerBackground}) no-repeat right center / auto 100%`,
  }
})

export const MainMenuDropdownBox = styled(Box)({
  background: '#00244e',
  borderRadius: 8,
  boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.35)',
  padding: '10px 30px 30px 30px',
  marginTop: '-8px',
})

export const MainMenuDropdownLineBox = styled(Box)({
  height: 1,
  transition: 'all 0.5s'
})

export const HeaderBottomRightBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
  height: 104,
  marginTop: -104
})

export const HeaderFullTitleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  height: 72,
  backgroundColor: 'rgba(221, 221, 221, 0.1)',
  borderLeft: `${theme.palette.offwhite.main} 2px solid`,
  borderRight: `${theme.palette.offwhite.main} 2px solid`,
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: '16px',
  color: theme.palette.offwhite.main,
  textTransform: theme.typography.mainmenu.textTransform,
  padding: '0px 30px',
  transition: 'all 1s linear',

  '@media(max-width: 1280px)': {
    fontSize: '14px',
  }
}))

export const FooterBottomBox = styled(Box)(({ theme }) => ({
  // height: '223px',
  height: '254px',
  marginTop: '-104px',
  paddingTop: '39px',
  borderBottom: `${theme.palette.offwhite.main} 1px solid`,

  '@media (max-width: 1199px)': {
    height: 'auto',
    marginTop: '0px',
    paddingBottom: '20px',
  }
}))

export const FooterContactInfoBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.offwhite.main,
}))

export const FooterContactInfoEngBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.roboto.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.offwhite.main,
  paddingTop: '5px'
}))

export const LanguageBox = styled(Box)(({ theme }) => ({
  width: '56px',
  fontFamily: theme.typography.roboto.fontFamily,
  color: theme.palette.offwhite.main,
  background: theme.palette.primary.main,
  borderTopLeftRadius: '8px',
  borderBottomLeftRadius: '8px',
  textTransform: 'uppercase',
  position: 'fixed',
  top: '30%',
  right: '0px',
  padding: '14px 0px',
  textAlign: 'center',
  cursor: 'pointer'
}))

export const LanguageMobileBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.roboto.fontFamily,
  color: theme.palette.offwhite.main,
  textTransform: 'uppercase',
  cursor: 'pointer',
  padding: '10px',
  marginRight: '20px',
}))

export const ToolbarBox = styled(Box)({
  width: '200px',
  position: 'fixed',
  top: 'calc(30% + 70px)',
  background: '#060E36',
  padding: '0px 16px',
  transition: 'all 0.7s',
  borderTopLeftRadius: '8px',
  borderBottomLeftRadius: '8px',

  '@media (max-width: 1199px)': {
    width: '90%',
    borderBottomLeftRadius: '0px',
    borderTopRightRadius: '8px',
    top: 'auto',
    left: '50%',
    transform: 'translateX(-50%)',
    paddingBottom: '20px',
  }
})

export const ToolbarLabelBox = styled(Box)(({ theme }) => ({
  height: '64px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  borderBottom: `${theme.palette.primary.main} 1px dotted`,
  transition: 'all 0.5s',

  '@media (max-width: 1199px)': {
    justifyContent: 'space-between',
  }
}))

export const ToolbarLabelIconBox = styled(Box)(({ theme }) => ({
  width: '32px',
  height: '32px',
  background: theme.palette.primary.main,
  color: theme.palette.offwhite.main,
  borderRadius: '8px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

export const ToolbarLabelTextBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.roboto.fontFamily,
  color: theme.palette.offwhite.main,
  fontSize: '20px',
  paddingLeft: '16px'
}))

export const ToolbarItemBox = styled(Box)({
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
})

export const ToolbarItemTextBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.roboto.fontFamily,
  color: theme.palette.offwhite.main,
}))

export const ToolbarArrowBox = styled(Box)({
  width: '31px',
  height: '40px',
  padding: '4px 0px 0px 8px',
  cursor: 'pointer',
})

export const ToolbarSubItemTextBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.roboto.fontFamily,
  color: theme.palette.offwhite.main,
  fontSize: theme.typography.regular.fontSize,
  marginLeft: '31px',
  cursor: 'pointer',
  borderBottom: `${theme.palette.offwhite.main} 1px solid`,
  width: '75%',
}))

export const RegularPageTitleBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: '28px',
  color: theme.palette.darkblue.main,
  textTransform: theme.typography.mainmenu.textTransform,
  height: '104px',
  marginTop: '-104px',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: '5',

  '@media (max-width: 1199px)': {
    height: 'auto',
    margin: '30px 0px',
    fontSize: '20px',
  }
}))

export const RegularPageFluidBox = styled(Box)({
  marginTop: '-104px',
  background: `url(${regularBackgroundLeft}) repeat-y left top, url(${regularBackground}) no-repeat right 0 top 104px / 100%`,

  '@media (max-width: 1199px)': {
    marginTop: '0px',
  }
})

export const RegularPageContentR5Box = styled(Box)({
  padding: '30px 0px 50px 0px',

  '@media (max-width: 1800px)': {
    padding: '30px 60px 50px 60px',
  },

  '@media (max-width: 1199px)': {
    padding: '0px 0px 30px 0px',
  }
})

export const RegularPageContent0Box = styled(Box)({
  padding: '30px 0px 50px 0px',

  '@media (max-width: 1800px)': {
    padding: '30px 60px 50px 60px',
  },

  '@media (max-width: 1199px)': {
    padding: '0px 0px 30px 0px',
  }
})

export const RegularPageContentL5R10Box = styled(Box)({
  padding: '30px 5% 50px 5%',

  '@media (max-width: 1199px)': {
    padding: '0px 0px 30px 0px',
  }
})

export const OnlyPaddingBox = styled(Box)({
  padding: '0px 0px 0px 0px',

  '@media (max-width: 1800px)': {
    padding: '0px 60px',
  },

  '@media (max-width: 1199px)': {
    padding: '0px 0px 0px 0px',
  }
})

export const BurgerMenuIconBox = styled(Box)({
  width: '32px',
  height: '32px',
  background: `url(${burgerMenuIcon}) no-repeat center`,
  cursor: 'pointer',
})

export const CloseIconBox = styled(Box)({
  width: '32px',
  height: '32px',
  background: `url(${closeIcon}) no-repeat center`,
  cursor: 'pointer',
})

export const MobileMenuBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  background: theme.palette.primary.main,
  color: theme.palette.offwhite.main,
  position: 'fixed',
  top: '0px',
  padding: '0px 20px',
  transition: 'all 0.5s',
  zIndex: '101',
  overflowY: 'auto',

  '@media (min-width: 900px)': {
    width: '50%',
  }
}))

export const MobileMenuHeaderBox = styled(Box)({
  height: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

export const MainMenuMobileItemBox = styled(Box)(({ theme }) => ({
  width: '100%',
  fontFamily: theme.typography.mainmenu.fontFamily,
  textTransform: theme.typography.mainmenu.textTransform,
  fontSize: '16px',
  lineHeight: '24px',
  cursor: 'pointer',
  padding: '5px 0px',
  marginBottom: '15px',
}))

export const RegularPageTitleLinkBox = styled(Box)({
  textDecoration: 'underline',
  cursor: 'pointer',

  '&:hover': {
    textDecoration: 'none',
  },
})

export const ProfileLinkBox = styled(Box)(({ theme }) => ({
  display: 'inline',
  color: theme.palette.secondary.main,
  textDecoration: 'underline',
  cursor: 'pointer',

  '&:hover': {
    textDecoration: 'none',
  },
}))

export const CircleWarningBox = styled(Box)(({ theme }) => ({
  width: '24px',
  height: '24px',
  borderRadius: '12px',
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.warning.main,
  color: 'white',
  cursor: 'pointer',

  '&:hover': {
    opacity: 0.8,
  }
}))

export const PdoContactBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  color: theme.palette.offwhite.main,
  fontSize: theme.typography.regular.fontSize,
  marginTop: '20px',
}))

export const PdpLinkBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  color: theme.palette.offwhite.main,
  fontSize: theme.typography.regular.fontSize,
  marginTop: '10px',
  cursor: 'pointer',

  '&:hover': {
    textDecoration: 'underline',
  }
}))