import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import { themeGeo, themeEng } from '../themes/theme'
import CssBaseline from '@mui/material/CssBaseline'
import { WrapperBox } from '../themes/boxLayout'
import ScrollToTop from '../helpers/scrollToTop'
import useWindowDimensions from '../helpers/dimensions'
import TagManager from 'react-gtm-module'
import settings from '../helpers/settings'
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'
import Language from '../components/layout/Language'
import Toolbar from '../components/layout/Toolbar'
import HeaderMobile from '../components/layout/HeaderMobile'
import Home from '../components/home/Home'
import News from '../components/news/News'
import NewsFullPage from '../components/news/NewsFullPage'
import Staff from '../components/staff/Staff'
import Founders from '../components/founders/Founders'
import Projects from '../components/projects/Projects'
import ProjectsOngoing from '../components/projects/ProjectsOngoing'
import Education from '../components/education/Education'
import Academy from '../components/education/Academy'
import CoursesActive from '../components/education/CoursesActive'
import Course from '../components/education/Course'
import Internet from '../components/internet/Internet'
import Cert from '../components/cert/Cert'
import Eduroam from '../components/eduroam/Eduroam'
import Edugain from '../components/edugain/Edugain'
import Software from '../components/software/Software'
import About from '../components/about/About'
import Vps from '../components/vps/Vps'
import Hosting from '../components/hosting/Hosting'
import Domains from '../components/domains/Domains'
import Aup from '../components/aup/Aup'
import Complaints from '../components/complaints/Complaints'
import Page404 from '../components/layout/Page404'

const AppRouter = () => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  useEffect(() => {
    const currentVersion = localStorage.getItem('appVersionGrenaGe');
    
    if (!currentVersion || (currentVersion && currentVersion !== settings.app_version)) {
      localStorage.setItem('appVersionGrenaGe', settings.app_version)
      
      setTimeout(() => {
        window.location.reload()
      }, 100)      
    } else {
      localStorage.setItem('appVersionGrenaGe', settings.app_version)
    }
  }, [])
  
  useEffect(() => {
    TagManager.initialize({ gtmId: settings.googleTagManager })
  }, [])

  useEffect(() => {
    document.documentElement.lang = lang
  }, [lang])
  
  return (
    <ThemeProvider theme={ lang === 'ka' ? themeGeo : themeEng }>
      <CssBaseline />
      <BrowserRouter>
        <ScrollToTop />
        <WrapperBox>
          { width > settings.desktopBreakpoint ? <Header /> : <HeaderMobile /> }
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/news' element={<News />} />
            <Route path='/news/:id' element={<NewsFullPage />} />
            <Route path='/team' element={<Staff />} />
            <Route path='/founders' element={<Founders />} />
            <Route path='/projects' element={<Projects />} />
            <Route path='/ongoing_projects' element={<ProjectsOngoing />} />
            <Route path='/education' element={<Education />} />
            <Route path='/education/:id' element={<Academy />} />
            <Route path='/courses_active' element={<CoursesActive />} />
            <Route path='/course/:id' element={<Course />} />
            <Route path='/internet' element={<Internet />} />
            <Route path='/cert-ge' element={<Cert />} />
            <Route path='/eduroam' element={<Eduroam />} />
            <Route path='/edugain' element={<Edugain />} />
            <Route path='/licensed-software' element={<Software />} />
            <Route path='/about-us' element={<About />} />
            <Route path='/virtualization' element={<Vps />} />
            <Route path='/hosting' element={<Hosting />} />
            <Route path='/domains' element={<Domains />} />
            <Route path='/aup' element={<Aup />} />
            <Route path='/customer-complaints' element={<Complaints />} />
            <Route path='*' element={<Page404 />} />
          </Routes>
        </WrapperBox>
        { width > settings.desktopBreakpoint && <Language /> }
        { width > settings.desktopBreakpoint && <Toolbar /> }
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default AppRouter