import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2'
import ConstructionIcon from '@mui/icons-material/Construction'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import PlaceIcon from '@mui/icons-material/Place'
import PhoneIcon from '@mui/icons-material/Phone'
import { IconContext } from 'react-icons'
import { FaFacebookSquare, FaTwitterSquare } from 'react-icons/fa'
import { FooterCopyrightStack } from '../../themes/stack'
import { FooterTopCenterGrid, FooterTopRightGrid } from '../../themes/grid'
import { FooterBox, FooterBottomBox, FooterContactInfoBox, FooterContactInfoEngBox, PdoContactBox, PdpLinkBox } from '../../themes/boxLayout'
import { companyInfoItems, socialNetworksItems } from '../../data/company'
import { setBurgerRight } from '../../reducers/burgerRight'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiCompany, apiSocial } from '../../helpers/urls'
import useWindowDimensions from '../../helpers/dimensions'
import ToolbarMobile from './ToolbarMobile'

const Footer = () => {
  const theme = useTheme()
  const { width } = useWindowDimensions()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const lang = useSelector(state => state.lang)

  const [companyInfo, setCompanyInfo] = useState(companyInfoItems)
  const [socialNetworks, setSocialNetworks] = useState(socialNetworksItems)
  const [toolbarMobileOpen, setToolbarMobileOpen] = useState(false)

  const socialIconsPosition = width > settings.desktopBreakpoint ? 'flex-start' : 'flex-end'

  const sxPointer = width <= settings.desktopBreakpoint ? { cursor: 'pointer' } : {}

  const handleSocialIconClick = (url) => {
    window.open(url, '_blank')
  }

  const handleAddressClick = (e) => {
    const url = 'https://www.google.com/maps/place/Georgian+Research+and+Educational+Networking+Association+GRENA/@41.7047913,44.7847268,17z/data=!3m1!4b1!4m6!3m5!1s0x40440cda9204f45f:0xff0f36e931f12625!8m2!3d41.704788!4d44.7868831!16s%2Fg%2F11dxd3w0c5?entry=ttu'

    if (e.ctrlKey || e.button === 1) {
      window.open(url, '_blank')
    } else if (e.type === 'click') {
      window.open(url, '_blank')
    }
  }

  const handlePhoneClick = () => {
    if (width <= settings.desktopBreakpoint) {
      const url = 'tel:' + companyInfo[0].phone
      window.open(url, '_self')
    }
  }

  const handlePdpFileOpen = () => {
    if (companyInfo[0].params && companyInfo[0].params.pdp_file) {
      const url = settings.s3URL + settings.s3Bucket + companyInfo[0].params.pdp_file
      window.open(url, '_blank')
    }    
  }

  const handleClickCustomerComplaints = (e) => {
    if (e.ctrlKey || e.button === 1) {
      window.open('/customer-complaints', '_blank')
    } else if (e.type === 'click') {
      navigate('/customer-complaints')
      dispatch(setBurgerRight('-100%'))
    }
  }

  const toggleToolbarMobile = () => {
    if (toolbarMobileOpen) setToolbarMobileOpen(false)
    else setToolbarMobileOpen(true)
  }

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      const response = await request(settings.domain + settings.api + apiCompany.getInfo, 'GET')

      if (response && response.length > 0) {
        setCompanyInfo(response)
      } else {
        setCompanyInfo([])
      }
    }

    const fetchSocialNetworks = async () => {
      const response = await request(settings.domain + settings.api + apiSocial.getItems, 'GET')

      if (response && response.length > 0) {
        setSocialNetworks(response)
      } else {
        setSocialNetworks([])
      }
    }

    if (settings.mode === 'live') {
      fetchCompanyInfo()
      fetchSocialNetworks()
    }
  }, [])
  
  return (
    <FooterBox>
      {
        width > settings.desktopBreakpoint ? (
          <Grid container>
            <FooterTopCenterGrid lg={2} lgOffset={ width > 2000 ? 6 : 5 }></FooterTopCenterGrid>
            <FooterTopRightGrid lg={ width > 2000 ? 4 : 5 }></FooterTopRightGrid>
          </Grid>
        ) : (
          <ToolbarMobile open={toolbarMobileOpen} toggle={toggleToolbarMobile} />
        )
      }
      <Container maxWidth='xl'>
        <FooterBottomBox>
          <Grid container>
            <Grid md={12} lg={5}>
              <Box>
                <Grid container rowSpacing={2} columnSpacing={4}>
                  <Grid xs={5} lg={5}>
                    <Stack
                      direction='row'
                      justifyContent='flex-start'
                      alignItems='top'
                      spacing={2}
                    >
                      <MailOutlineIcon fontSize='large' sx={{ color: theme.palette.offwhite.main }} />
                      <FooterContactInfoEngBox>{companyInfo[0].email}</FooterContactInfoEngBox>
                    </Stack>
                  </Grid>
                  {
                    width <= settings.desktopBreakpoint && (
                      <Grid xs={7}>
                        <Stack
                          direction='row'
                          justifyContent='flex-end'
                          alignItems='center'
                          spacing={1}
                        >
                          <Box sx={{ cursor: 'pointer', color: '#DDDDDD' }} onClick={toggleToolbarMobile}>
                            <ConstructionIcon fontSize='large' />
                          </Box>
                        </Stack>
                      </Grid>
                    )
                  }
                  <Grid xs={12} lg={7}>
                    <Stack
                      direction='row'
                      justifyContent='flex-start'
                      alignItems='top'
                      spacing={2}
                      sx={{ cursor: 'pointer' }}
                      onClick={handleAddressClick}
                      onMouseDown={handleAddressClick}
                    >
                      <PlaceIcon fontSize='large' sx={{ color: theme.palette.offwhite.main }} />
                      <FooterContactInfoBox>{companyInfo[0].address[lang]}</FooterContactInfoBox>
                    </Stack>
                  </Grid>
                  <Grid xs={5} lg={5}>
                    <Stack
                      direction='row'
                      justifyContent='flex-start'
                      alignItems='top'
                      spacing={2}
                      sx={sxPointer}
                      onClick={handlePhoneClick}
                    >
                      <PhoneIcon fontSize='large' sx={{ color: theme.palette.offwhite.main }} />
                      <FooterContactInfoEngBox>{companyInfo[0].phone}</FooterContactInfoEngBox>
                    </Stack>
                  </Grid>
                  <Grid xs={7} lg={7}>
                    <Stack
                      direction='row'
                      justifyContent={socialIconsPosition}
                      alignItems='top'
                      spacing={1}
                    >
                      {
                        socialNetworks.map((item, index) => {
                          let socialIcon = ''
                          if (item.logo_url === 'facebook') socialIcon = <FaFacebookSquare />
                          if (item.logo_url === 'twitter') socialIcon = <FaTwitterSquare />

                          return (
                            <Box key={index} sx={{ cursor: 'pointer' }} onClick={() => handleSocialIconClick(item.website)}>
                              <IconContext.Provider value={{ color: theme.palette.offwhite.main, size: '26px' }}>
                                {socialIcon}
                              </IconContext.Provider>
                            </Box>
                          )
                        })
                      }
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          { (companyInfo[0].params && companyInfo[0].params.pdo_contact) && <PdoContactBox>{companyInfo[0].params.pdo_contact[lang]}</PdoContactBox> }

          { (companyInfo[0].params && companyInfo[0].params.pdp_title && companyInfo[0].params.pdp_file) && <PdpLinkBox onClick={handlePdpFileOpen} onMouseDown={handlePdpFileOpen}>{companyInfo[0].params.pdp_title[lang]}</PdpLinkBox> }

          { (companyInfo[0].params && companyInfo[0].params.complaints_1 && companyInfo[0].params.complaints_1[lang]) && <PdpLinkBox onClick={handleClickCustomerComplaints} onMouseDown={handleClickCustomerComplaints}>{companyInfo[0].params.complaints_1[lang]}</PdpLinkBox> }
        </FooterBottomBox>
        <FooterCopyrightStack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          spacing={0}
        >
          <Box>GRENA &copy; 1999-{new Date().getFullYear()}</Box>
          <Box>All rights reserved</Box>
        </FooterCopyrightStack>
      </Container>
    </FooterBox>
  )
}

export default Footer